import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm$alarmAmount$, _vm$alarmAmount$2, _vm$alarmAmount$3, _vm$alarmAmount$4, _vm$alarmAmount$5, _vm$alarmAmount$6, _vm$alarmAmount$7;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "pageTitle"
  }, [_vm._v("富強鑫展示畫面即時資訊")]), _c('div', {
    staticClass: "alert_Info"
  }, [_c('ul', [_c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "系統正常營運"
    }
  }, [_c('i', {
    staticClass: "fas fa-check-circle mr-1",
    staticStyle: {
      "font-size": "20px",
      "color": "var(--success)"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.systemNormal")) + " (" + _vm._s((_vm$alarmAmount$ = _vm.alarmAmount["0"]) !== null && _vm$alarmAmount$ !== void 0 ? _vm$alarmAmount$ : 0) + ") ")])])]), _c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "依通訊裝置異常告警數值定義(不含 Inv 斷訊)"
    }
  }, [_c('i', {
    staticClass: "fas fa-radiation-alt mr-1",
    staticStyle: {
      "font-size": "20px",
      "color": "var(--yellow)"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.partialSystemError")) + " (" + _vm._s((_vm$alarmAmount$2 = _vm.alarmAmount["3"]) !== null && _vm$alarmAmount$2 !== void 0 ? _vm$alarmAmount$2 : 0) + ") ")])])]), _c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "案場斷網 30 分鐘內"
    }
  }, [_c('img', {
    staticClass: "d-block mr-1",
    attrs: {
      "src": require("../assets/hourglass.svg"),
      "alt": "系統短暫斷線圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.deviceDisconnectedShort")) + " (" + _vm._s((_vm$alarmAmount$3 = _vm.alarmAmount["7"]) !== null && _vm$alarmAmount$3 !== void 0 ? _vm$alarmAmount$3 : 0) + ") ")])])]), _c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "停電後 Buffer 回補、斷網後計算回補、特殊計算回補、  三日內無資料"
    }
  }, [_c('img', {
    staticClass: "d-block mr-1",
    attrs: {
      "src": require("../assets/file-error.svg"),
      "alt": "資料缺漏回補圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.backfill")) + " (" + _vm._s((_vm$alarmAmount$4 = _vm.alarmAmount["5"]) !== null && _vm$alarmAmount$4 !== void 0 ? _vm$alarmAmount$4 : 0) + ") ")])])]), _c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "Inv 斷訊(null)、Inv 未發電"
    }
  }, [_c('img', {
    staticClass: "d-block mr-1",
    attrs: {
      "src": require("../assets/electrical.svg"),
      "alt": "發電數據異常圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.noPowerData")) + " (" + _vm._s((_vm$alarmAmount$5 = _vm.alarmAmount["2"]) !== null && _vm$alarmAmount$5 !== void 0 ? _vm$alarmAmount$5 : 0) + ") ")])])]), _c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "d-flex align-items-center",
    attrs: {
      "title": "案場斷網大於 30 分鐘"
    }
  }, [_c('img', {
    staticClass: "d-block mr-1",
    attrs: {
      "src": require("../assets/smart-plug.svg"),
      "alt": "系統未連線圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.deviceDisconnected")) + " (" + _vm._s((_vm$alarmAmount$6 = _vm.alarmAmount["6"]) !== null && _vm$alarmAmount$6 !== void 0 ? _vm$alarmAmount$6 : 0) + ") ")])])]), _c('li', [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom.html",
      value: {
        title: '1. Inv 有發電但效率不佳(RA)<br>2. Mppt 不發電或發電不佳<br>3. 保證PR / 保證發電量未達標'
      },
      expression: "{\n            title:\n              '1. Inv 有發電但效率不佳(RA)<br>2. Mppt 不發電或發電不佳<br>3. 保證PR / 保證發電量未達標',\n          }",
      modifiers: {
        "hover": true,
        "bottom": true,
        "html": true
      }
    }],
    staticClass: "d-flex align-items-center"
  }, [_c('img', {
    staticClass: "mr-1",
    attrs: {
      "src": require("../assets/poor-power.svg"),
      "alt": "發電效率不佳圖示",
      "width": "20"
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("realTime.status.poorPower")) + " (" + _vm._s((_vm$alarmAmount$7 = _vm.alarmAmount["9"]) !== null && _vm$alarmAmount$7 !== void 0 ? _vm$alarmAmount$7 : 0) + ") ")])])])])]), _vm._l(_vm.fuqiangxinData, function (i, idx) {
    var _i$IRR, _i$E_today, _vm$factoryAccMonData, _vm$factoryAccMonData2, _vm$factoryAccMonData3, _vm$factoryAccMonData4, _vm$factoryAccMonData5, _vm$factoryDayAvgPeak, _vm$factoryDayAvgPeak2, _vm$factoryDayAvgPeak3, _vm$factoryDayAvgPeak4, _vm$factoryDayAvgPeak5, _vm$factoryDayAvgPeak6, _vm$factoryDayAvgPeak7, _vm$factoryDayAvgPeak8, _i$acp, _i$dcp, _i$PrRaMon, _i$PrRaMon2, _i$capacity;
    return _c('div', {
      key: i.key,
      staticClass: "newRealTimeStyle",
      attrs: {
        "id": i.factoryId
      }
    }, [_c('table', [_c('div', {
      staticClass: "rwdFactoryName"
    }, [_vm._v(_vm._s(i.displaySiteName))]), _c('tr', [_c('th', {
      staticStyle: {
        "font-size": "22px",
        "width": "12%"
      },
      attrs: {
        "rowspan": "2"
      }
    }, [_vm._v(" " + _vm._s(i.displaySiteName) + " ")]), _c('th', [_vm._v("設置地點")]), _c('th', [_vm._v("設置容量(kWp)")]), _vm._m(0, true), _c('th', [_vm._v("模組溫度(°C)")]), _c('th', [_vm._v("今日發電量(kWh)")]), _c('th', {
      staticStyle: {
        "width": "10%"
      }
    }, [_vm._v(" 本月累計發電量(kWh)  "), _c('i', {
      staticClass: "fas fa-exclamation-circle",
      staticStyle: {
        "opacity": "0.9"
      },
      attrs: {
        "id": 'popover-accMon' + idx
      }
    })]), _c('b-popover', {
      attrs: {
        "target": 'popover-accMon' + idx,
        "triggers": "hover",
        "placement": "right"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("更新頻率 1 小時 / 次")];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" 累計數據每小時 05 分時，計算後更新"), _c('br'), _vm._v("( 取得最新數據，請重新整理頁面 ) ")]), _c('th', {
      staticStyle: {
        "width": "10%"
      }
    }, [_vm._v(" 平均每日每 kW 發電量  "), _c('i', {
      staticClass: "fas fa-exclamation-circle",
      staticStyle: {
        "opacity": "0.9"
      },
      attrs: {
        "id": 'popover-peakWatt' + idx
      }
    })]), _c('b-popover', {
      attrs: {
        "target": 'popover-peakWatt' + idx,
        "triggers": "hover",
        "placement": "right"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v("更新頻率 1 小時 / 次")];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" 累計數據每小時 05 分時，計算後更新"), _c('br'), _vm._v("( 取得最新數據，請重新整理頁面 ) ")]), _c('th', [_vm._v("INV效率(%)")]), _c('th', [_vm._v("今日PR(%)")]), _c('th', [_vm._v("案場狀態")])], 1), _c('tr', [_c('td', {
      staticClass: "set_site",
      attrs: {
        "data-title": "設置地點"
      }
    }, [_vm._v(" " + _vm._s(_vm.fuqiangxinSetSite[idx]) + " ")]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.capacityAll && i.displayAlertLevel !== 6 ? _c('td', {
      attrs: {
        "data-title": "設置容量(kWp)"
      }
    }, [_vm._v(" " + _vm._s(i.capacityAll.toFixed(2)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "設置容量(kWp)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "設置容量(kWp)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.IRR && (_i$IRR = i.IRR) !== null && _i$IRR !== void 0 && _i$IRR.length && !i.IRR.includes(null) && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "日照強度(W/㎡)"
      }
    }, [_vm._v(" " + _vm._s(_vm.dbDataHandle(_vm.handleIRRArray(i.IRR) * 1000, 0)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "日照強度(W/㎡)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "日照強度(W/㎡)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.PVTemp && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "模組溫度(°C)"
      }
    }, [_vm._v(" " + _vm._s(i.PVTemp) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "模組溫度(°C)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "模組溫度(°C)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.E_today && (_i$E_today = i.E_today) !== null && _i$E_today !== void 0 && _i$E_today.length && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "今日發電量(kWh)"
      }
    }, [_vm._v(" " + _vm._s(_vm.sumValue(i.E_today)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "今日發電量(kWh)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "今日發電量(kWh)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [(_vm$factoryAccMonData = _vm.factoryAccMonData) !== null && _vm$factoryAccMonData !== void 0 && (_vm$factoryAccMonData2 = _vm$factoryAccMonData[idx]) !== null && _vm$factoryAccMonData2 !== void 0 && _vm$factoryAccMonData2.acpAll && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "本月累計發電量(kWh)"
      }
    }, [_vm._v(" " + _vm._s(_vm.factoryAccMonData[idx].acpAll.toFixed(2)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "本月累計發電量(kWh)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "本月累計發電量(kWh)"
      }
    }, [_vm._v(" " + _vm._s((_vm$factoryAccMonData3 = (_vm$factoryAccMonData4 = _vm.factoryAccMonData) === null || _vm$factoryAccMonData4 === void 0 ? void 0 : (_vm$factoryAccMonData5 = _vm$factoryAccMonData4[idx]) === null || _vm$factoryAccMonData5 === void 0 ? void 0 : _vm$factoryAccMonData5.acpAll) !== null && _vm$factoryAccMonData3 !== void 0 ? _vm$factoryAccMonData3 : "null") + " ")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [(_vm$factoryDayAvgPeak = _vm.factoryDayAvgPeakWattData) !== null && _vm$factoryDayAvgPeak !== void 0 && (_vm$factoryDayAvgPeak2 = _vm$factoryDayAvgPeak[idx]) !== null && _vm$factoryDayAvgPeak2 !== void 0 && _vm$factoryDayAvgPeak2.avgPeakWatt && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "平均每日每 kW 發電量"
      }
    }, [_vm._v(" " + _vm._s((_vm$factoryDayAvgPeak3 = _vm.factoryDayAvgPeakWattData) === null || _vm$factoryDayAvgPeak3 === void 0 ? void 0 : (_vm$factoryDayAvgPeak4 = _vm$factoryDayAvgPeak3[idx]) === null || _vm$factoryDayAvgPeak4 === void 0 ? void 0 : (_vm$factoryDayAvgPeak5 = _vm$factoryDayAvgPeak4.avgPeakWatt) === null || _vm$factoryDayAvgPeak5 === void 0 ? void 0 : _vm$factoryDayAvgPeak5.toFixed(2)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "平均每日每 kW 發電量"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "平均每日每 kW 發電量"
      }
    }, [_vm._v(" " + _vm._s((_vm$factoryDayAvgPeak6 = (_vm$factoryDayAvgPeak7 = _vm.factoryDayAvgPeakWattData) === null || _vm$factoryDayAvgPeak7 === void 0 ? void 0 : (_vm$factoryDayAvgPeak8 = _vm$factoryDayAvgPeak7[idx]) === null || _vm$factoryDayAvgPeak8 === void 0 ? void 0 : _vm$factoryDayAvgPeak8.avgPeakWatt) !== null && _vm$factoryDayAvgPeak6 !== void 0 ? _vm$factoryDayAvgPeak6 : "null") + " ")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [i.acp && i.dcp && (_i$acp = i.acp) !== null && _i$acp !== void 0 && _i$acp.length && (_i$dcp = i.dcp) !== null && _i$dcp !== void 0 && _i$dcp.length && i.displayAlertLevel !== 6 ? _c('td', {
      key: i.dataTimeFromNow,
      attrs: {
        "data-title": "INV效率(%)"
      }
    }, [_vm._v(" " + _vm._s(_vm.dbDataHandle(_vm.handleInvSum(i.acp) / _vm.handleInvSum(i.dcp) * 100, 0)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "INV效率(%)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "INV效率(%)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [typeof i.PrRaMon !== 'string' && (_i$PrRaMon = i.PrRaMon) !== null && _i$PrRaMon !== void 0 && _i$PrRaMon.ACP && (_i$PrRaMon2 = i.PrRaMon) !== null && _i$PrRaMon2 !== void 0 && _i$PrRaMon2.PR && i.capacity && (_i$capacity = i.capacity) !== null && _i$capacity !== void 0 && _i$capacity.length && i.displayAlertLevel !== 6 ? _c('td', {
      attrs: {
        "data-title": "今日PR(%)"
      }
    }, [_vm._v(" " + _vm._s(_vm.dbDataHandle(i.PrRaMon.PR * 100, 1)) + " ")]) : i.displayAlertLevel === 6 ? _c('td', {
      attrs: {
        "data-title": "今日PR(%)"
      }
    }, [_vm._v(" - ")]) : _c('td', {
      attrs: {
        "data-title": "今日PR(%)"
      }
    }, [_vm._v("null")])]), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [_c('td', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.right",
        modifiers: {
          "right": true
        }
      }],
      attrs: {
        "data-title": "案場狀態",
        "title": _vm.displayAlertLevelToText(i.displayAlertLevel)
      }
    }, [i.displayAlertLevel === 0 ? _c('i', {
      staticClass: "fas fa-check-circle",
      staticStyle: {
        "color": "var(--success)"
      }
    }) : i.displayAlertLevel === 1 || i.displayAlertLevel === 2 ? _c('img', {
      staticClass: "d-block mx-auto",
      attrs: {
        "src": require("../assets/electrical.svg"),
        "alt": "發電數據異常圖示",
        "width": "20"
      }
    }) : i.displayAlertLevel === 3 ? _c('i', {
      staticClass: "fas fa-radiation-alt",
      staticStyle: {
        "color": "var(--yellow)"
      }
    }) : i.displayAlertLevel === 5 ? _c('img', {
      staticClass: "d-block mx-auto",
      attrs: {
        "src": require("../assets/file-error.svg"),
        "alt": "資料缺漏回補圖示",
        "width": "20"
      }
    }) : i.displayAlertLevel === 6 ? _c('img', {
      staticClass: "d-block mx-auto",
      attrs: {
        "src": require("../assets/smart-plug.svg"),
        "alt": "系統未連線圖示",
        "width": "20"
      }
    }) : i.displayAlertLevel === 7 ? _c('img', {
      staticClass: "d-block mx-auto",
      attrs: {
        "src": require("../assets/hourglass.svg"),
        "alt": "系統短暫斷線圖示",
        "width": "20"
      }
    }) : i.displayAlertLevel === 4 ? _c('i', {
      staticClass: "fas fa-search-minus",
      staticStyle: {
        "color": "var(--purple)"
      }
    }) : i.displayAlertLevel === 9 ? _c('img', {
      staticClass: "d-block mx-auto",
      attrs: {
        "src": require("../assets/poor-power.svg"),
        "alt": "發電效率不佳圖示",
        "width": "20"
      }
    }) : _vm._e()])])], 1)])]);
  }), _c('div', {
    staticClass: "newRealTimeStyle"
  }, [_c('table', [_c('div', {
    staticClass: "rwdFactoryName"
  }, [_vm._v("合計")]), _c('tr', [_c('th', {
    staticStyle: {
      "font-size": "22px",
      "width": "12%"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("合計")]), _c('th', [_vm._v("總設置容量(kWp)")]), _c('th', [_vm._v("今日發電量合計(kW)")]), _vm._m(1), _c('b-popover', {
    attrs: {
      "target": "popover-siteAcpToday",
      "triggers": "hover",
      "placement": "right"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("更新頻率 1 小時 / 次")];
      },
      proxy: true
    }])
  }, [_vm._v(" 累計數據每小時 05 分時，計算後更新"), _c('br'), _vm._v("( 取得最新數據，請重新整理頁面 ) ")])], 1), _c('tr', [_c('td', {
    attrs: {
      "data-title": "總設置容量(kWp)"
    }
  }, [_vm._v(" " + _vm._s(_vm.siteCapacity) + " ")]), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_c('td', {
    attrs: {
      "data-title": "今日發電量合計(kW)"
    }
  }, [_vm._v(" " + _vm._s(_vm.siteAcpToday) + " ")])]), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_c('td', {
    attrs: {
      "data-title": "本月累積發電量合計(kW)"
    }
  }, [_vm._v(" " + _vm._s(_vm.siteAcpSum.toFixed(2)) + " ")])])], 1)])]), _c('div', {
    staticClass: "newRealTimeStyle"
  }, [_c('table', [_c('div', {
    staticClass: "rwdFactoryName"
  }, [_vm._v("售電金額合計")]), _c('tr', [_c('th', {
    staticStyle: {
      "font-size": "22px",
      "width": "12%"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("售電金額合計")]), _c('th', [_vm._v("今日售電金額合計")]), _c('th', [_vm._v(" 本月累積售電金額合計  "), _c('i', {
    staticClass: "fas fa-exclamation-circle",
    staticStyle: {
      "opacity": "0.9"
    },
    attrs: {
      "id": "popover-sitePriceSum"
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "popover-sitePriceSum",
      "triggers": "hover",
      "placement": "right"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("更新頻率 1 小時 / 次")];
      },
      proxy: true
    }])
  }, [_vm._v(" 累計數據每小時 05 分時，計算後更新"), _c('br'), _vm._v("( 取得最新數據，請重新整理頁面 ) ")])], 1)])]), _c('tr', [_c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_c('td', {
    attrs: {
      "data-title": "今日售電金額合計"
    }
  }, [_vm._v(" " + _vm._s(_vm.sitePowerPriceToday) + " ")])]), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_c('td', {
    attrs: {
      "data-title": "本月累積售電金額合計"
    }
  }, [_vm._v(" " + _vm._s(_vm.sitePowerPriceMonth) + " ")])])], 1)])])], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('th', [_vm._v("日照強度"), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("(W/㎡)")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('th', [_vm._v(" 本月累積發電量合計(kW)  "), _c('i', {
    staticClass: "fas fa-exclamation-circle",
    staticStyle: {
      "opacity": "0.9"
    },
    attrs: {
      "id": "popover-siteAcpToday"
    }
  })]);
}];
export { render, staticRenderFns };